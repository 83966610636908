/**
 * 通用请求接口
 */
import axios from 'axios';
import {EncryptMessage,DecryptMessage} from './commons';
import Element from "element-ui";
import constants from './constants';
const http = axios.create({
    baseURL: '',
    timeout: 60*1000
});


function alert(type,msg){
    Element.Notification({
        title: '温馨提示',
        message: msg,
        dangerouslyUseHTMLString: true,
        type: type,////"success","warning"
        position: 'bottom-right',
        duration: 3000
    });
}


// request拦截器
http.interceptors.request.use(config => {
    let token=sessionStorage.getItem('AccessToken');
    token?config.headers['AccessToken'] = token:'';
    config.baseURL=constants.getDomain();
    if(config.url.includes('/file/')){
        config.headers['Content-Type']='multipart/form-data';
        return config;
    }else{
        config.data.entity=EncryptMessage(token,config.data.entity);
        return config;
    }
}, error => {
    return Promise.reject(error)
});


// respone拦截器
http.interceptors.response.use(response => {
    if (response.config.responseType === 'blob' || response.config.responseType === 'arraybuffer'  ) {
        return Promise.resolve(response.data);
    } else {
        if(response.data.entity){
            let token=response.config.headers.AccessToken;
            try {
                if(response.config.url=='/default/login'){
                    token=null;
                }
                let obj = JSON.parse(DecryptMessage(token,response.data.entity));
                return Promise.resolve(obj);
            } catch (e) {
                console.log(response.config.url+",返回数据解析错误!");
            }
        }else{
            console.log(response.config.url+"返回数据对象为空!");
        }
    }
} ,error => {
    let errorStr='';
    if(error.response){
        if(error.response.status==400){
            if(error.response.data  instanceof Blob){
                error.response.data.text().then(function(text) {
                    if(JSON.parse(text).entity!='文件不存在！'){
                        console.log("error----",JSON.parse(text).entity);
                    }
                });
            }else if(error.response.data.entity){
                return Promise.reject(error.response.data.entity);
            }
        }else if(error.response.data){
            if(error.response.data.message){
                console.log("error----",error.response.data.message);
                //errorStr=error.response.data.message;
            }else if(error.response.data.entity){
                console.log("error----",error.response.data.entity);
               // errorStr=error.response.data.entity;
            }
        }else{
            if(error.response.status==404){
                errorStr="服务器未找到此请求的接口：”"+error.response.config.url+"”";
                return Promise.reject(errorStr);
            }
        }
    }else{
        if(error.toString().includes('Network Error')){
            if(!location.href.includes('equipment/play')) {
                sessionStorage.clear();
                errorStr="没有连接上服务器！请检查服务是否启动或网络是否异常!";
                return Promise.reject(errorStr)
            }
        }
    }

});
export default http